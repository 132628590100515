import React from "react";
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import SubscribeForm from "../components/subscribeForm";
import { HeaderPlaceholder } from "../styles/ui.styles";
import type { DataSourcesQuery } from "../../graphql-types";

interface Props {
  data: DataSourcesQuery;
}

const Press: React.FC<Props> = ({ data }) => {
  return (
    <>
      <SEO title={"Data Sources"} description={"Data Sources"} />
      <Layout>
        <HeaderPlaceholder />
        <Wrapper>
          <h1>Supported Data Sources</h1>
          <p>
            Fastrrr support files from the following vendors and devices. Follow
            the links to learn more.
          </p>
          <DataSourcesBlock>
            <div>
              <h4>Sailing Data</h4>
              <p>GPS and performance data</p>
              <ul>
                {data.allMdx.nodes
                  .filter((node) =>
                    node?.frontmatter?.category?.includes("sailingData")
                  )
                  .map((node) => (
                    <li key={node.id}>
                      <Link to={`/data-sources/${node.slug}`}>
                        {node?.frontmatter?.displayName}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
            <div>
              <h4>Tracking Data</h4>
              <p>Tracks recorded on tracking platforms</p>
              <ul>
                {data.allMdx.nodes
                  .filter((node) =>
                    node?.frontmatter?.category?.includes("trackingData")
                  )
                  .map((node) => (
                    <li key={node.id}>
                      <Link to={`/data-sources/${node.slug}`}>
                        {node?.frontmatter?.displayName}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
            <div>
              <h4>Course Files</h4>
              <p>Import start times and course coordinates </p>
              <ul>
                {data.allMdx.nodes
                  .filter((node) =>
                    node?.frontmatter?.category?.includes("courseFiles")
                  )
                  .map((node) => (
                    <li key={node.id}>
                      <Link to={`/data-sources/${node.slug}`}>
                        {node?.frontmatter?.displayName}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
            <div>
              <h4>Polar Files</h4>
              <p>Add target numbers based on TWS</p>
              <ul>
                {data.allMdx.nodes
                  .filter((node) =>
                    node?.frontmatter?.category?.includes("polarFiles")
                  )
                  .map((node) => (
                    <li key={node.id}>
                      <Link to={`/data-sources/${node.slug}`}>
                        {node?.frontmatter?.displayName}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </DataSourcesBlock>
        </Wrapper>
        <SubscribeForm />
      </Layout>
    </>
  );
};

export default Press;

export const data = graphql`
  query DataSources {
    allMdx(
      filter: { fields: { source: { eq: "data-sources" } } }
      sort: { fields: slug, order: ASC }
    ) {
      nodes {
        frontmatter {
          displayName
          category
        }
        slug
        id
      }
    }
  }
`;

const Wrapper = styled.div`
  padding: 0 var(--gutter);
  max-width: 1600px;
  margin: 0 auto;
  padding-bottom: 100px;
  h2 {
    font-size: min(
      max(2.4rem, calc(2.4rem + ((1vw - 0.48rem) * 0.8333))),
      3rem
    );
    min-height: 0vw;
    margin-top: 50px;
  }
  ul {
    padding: 0;
  }
  p,
  li {
    list-style: none;
    line-height: min(
      max(2.8rem, calc(2.8rem + ((1vw - 0.48rem) * 1.1111))),
      3.6rem
    );
    min-height: 0vw;

    transition: 0.2s;
  }
  a {
    text-decoration: underline;
    color: var(--color-orange);
    transition: 0.2s;
    &:hover {
      color: var(--color-orange-hovered);
    }
  }
`;

const DataSourcesBlock = styled.div`
  columns: 2 400px;
  padding-top: 32px;

  div:first-child h4 {
    margin-top: 0;
  }

  div {
    break-inside: avoid;
  }
`;
